<template>
  <div>
    <!-- <b-button @click="getData()">refresh</b-button> -->
    <main-filter-component class="mb-2" @selectedFilters="selectedFilters" :isLoading="isLoading"/>
    <b-row class="rounded-lg px-0 py-2">
      <b-col cols="12">
        <general-table :items="dataGeneral"  :key="keyTable" :isLoading="isLoading" @selectedItem="selectedItem"/>
      </b-col>
      <b-col cols="12" xl="6">
        <for-classification-table :viewDefault="viewClas" :items="dataForClassification" :isLoading="isLoading" :key="keyTable" @selectedItem="selectedItem" @selectView="selectViewClassification"/>
      </b-col>
      <b-col cols="12" xl="6">
        <for-bureau-table :viewDefault="viewBureau" :items="dataForBureau" :key="keyTable" :isLoading="isLoading" @selectedItem="selectedItem" @selectView="selectViewBureau"/>
      </b-col>
      <b-col cols="12" xl="12">
        <for-account-type-table :viewDefault="viewTypeAcc" :items="dataForAccountType" :isLoading="isLoading" :key="keyTable" @selectedItem="selectedItem" @selectView="selectViewTypeAccount"/>
      </b-col>
    </b-row>

    <!--MODAL DEATILS-->
    <details-modals-accounts
      v-if="showModalDetails"
      :typeNcr="typeNcr"
      :source="sourceOption"
      :month="monthSelected"
      :year="yearSelected"
      :process="paramsDetails.process"
      :condition="paramsDetails.condition"
      :valueCondition="paramsDetails.valueCondition"
      :filters="filterParams"
      @closeModal="closeDetails"
      />
  </div>
</template>
<script>
import ForBureauTable from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/components/ForBureauTable.vue";
import ForClassificationTable from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/components/ForClassificationTable.vue";
import GeneralTable from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/components/GeneralTable.vue";
import ForAccountTypeTable from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/components/ForAccountTypeTable.vue";
import RemoveJourneyServices from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/services/removeJourney.services.js";
import moment from "moment";
import DetailsModalsAccounts from '@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/modal/DetailsModalsAccounts.vue';
import MainFilterComponent from '@/views/specialists/sub-modules/financial-approval/views/dashboard/component/commons/MainFilter.vue';

export default {
  components: {
    ForBureauTable,
    ForClassificationTable,
    GeneralTable,
    ForAccountTypeTable,
    DetailsModalsAccounts,
    MainFilterComponent
  },
  data() {
    return {
      isLoading: false,
      dataGeneral: [],
      dataForClassification: [],
      dataForBureau: [],
      dataForAccountType: [],
      keyTable: 0,
      typeNcr: null,
      sourceOption: null,
      automaticOptions: [],
      manualOptions: [],
      monthSelected: null,
      currentYear: parseInt(moment().format("YYYY"), 10),
      optionsYears: [],
      yearSelected: null,
      optionsMonths: [],
      showModalDetails: false,
      paramsDetails: {
        process: 0,
        condition: 0,
        valueCondition: 0,
      },
      filterParams: {},
      isLoading: false,
      viewClas: null,
      viewBureau: null,
      viewTypeAcc: null,
    };
  },
  async mounted() {
    this.generateYears();
  },
  watch: {
    async yearSelected(newVal){
      this.generateMonths();
    },
  },
  methods: {
    async getData() {
      try {
        this.isLoading = true;
        const params = {
          "typeNcr": this.typeNcr,
          "source": this.sourceOption,
          "year": this.yearSelected,
          "month": this.monthSelected
        }
        this.isLoading = true;
        const { data } = await RemoveJourneyServices.getRemovedJourney(params);
        const { general, for_classification, for_bureau, for_account_type } = data;
        this.generateDataGeneral(general);
        this.generateForClassification(for_classification);
        this.generateDataForBureau(for_bureau)
        this.generateDataForAccountType(for_account_type);
        this.keyTable+=1;
      } catch (error) {
        this.showErrorSwal(error);
        console.log("error: ", error);
      } finally {
        this.isLoading = false;
        this.isLoading = false;
      }
    },
    generateYears() {
      this.optionsYears = [];
      for (let index = 2022; index <= this.currentYear; index++) {
        this.optionsYears.push({ id: index, value: index });
      }
      this.yearSelected = this.currentYear;
    },
    generateMonths() {
      const months =
        this.yearSelected < this.currentYear
          ? 12
          : parseInt(moment().format("MM"), 10);
      this.optionsMonths = [];
      for (let index = 1; index <= months; index++) {
        const myMonth = moment(index, "MM").format("MMMM");
        this.optionsMonths.push({ id: index, value: myMonth });
      }
      const currentMonth = parseInt(moment().format("MM"), 10);
      this.monthSelected =
        this.yearSelected < this.currentYear ||
        this.monthSelected <= currentMonth
          ? this.monthSelected
          : currentMonth;
    },
    generateDataGeneral(data) {
      this.dataGeneral = [
        {
          item: "GENERATED BY SOFT",
          quantity: data.total_gbs,
          process: 'gen by soft',
          condition: 'total'
        },
        {
          item: "SELECTED BY SPECIALIST",
          quantity: data.total_sbsp,
          process: 'sel by specialist',
          condition: 'total'
        },
        {
          item: "SELECTED BY SELLER",
          quantity: data.total_sbse,
          process: 'sel by seller',
          condition: 'total'
        },
        {
          item: "SOLD",
          quantity: data.total_sold,
          process: 'sold',
          condition: 'total'
        },
        {
          item: "DISPUTED",
          quantity: data.total_disp,
          process: 'disputed',
          condition: 'total'
        },
        {
          item: "DELETED / UPDATED",
          quantity: data.total_du,
          process: 'deleted updated',
          condition: 'total'
        },
      ];
    },
    generateForClassification(data){
      const keys = {
        disp: "DISPUTED",
        du: "DELETED / UPDATED",
        gbs: "GENERATED BY SOFT",
        sbse: "SELECTED BY SELLER",
        sold: "SOLD",
        sbsp: "SELECTED BY SPECIALIST",
      };
      const process = {gbs: 'gen by soft', sbsp: 'sel by specialist',sold: 'sold', sbse: 'sel by seller', disp: 'disputed', du: 'deleted updated'};
      const keysObject = Object.keys(data);

      this.dataForClassification = keysObject.map((item) => {
        const { high, medium, low, total } = data[item];
        return {
          item: keys[item],
          high,
          medium,
          low,
          total,
          process: process[item] || '',
          condition: 'classification',
        };
      });
    },
    generateDataForBureau(data){
       const keys = {
        disp: "DISPUTED",
        du: "DELETED / UPDATED",
        gbs: "GENERATED BY SOFT",
        sbse: "SELECTED BY SELLER",
        sold: "SOLD",
        sbsp: "SELECTED BY SPECIALIST",
      };
      const process = {gbs: 'gen by soft', sbsp: 'sel by specialist', sbse: 'sel by seller', sold: 'sold', disp: 'disputed', du: 'deleted updated'};
      const keysObject = Object.keys(data);

      this.dataForBureau = keysObject.map((item) => {
        const { transunion, experian, equifax, total } = data[item];
        return {
          item: keys[item],
          transunion,
          experian,
          equifax,
          total,
          process: process[item] || 0,
          condition: 'bureau'
        };
      });
    },
    generateDataForAccountType(data){
      const keysObject = Object.keys(data);
      this.dataForAccountType = keysObject.map(item => {
        const { by_seller, by_soft, by_specialists, sold, deleted, disputed } = data[item];
        const typeAccount = (item.toUpperCase()).replace('_',' ');

        return {
          item: typeAccount,
          by_seller,
          by_soft,
          by_specialists,
          sold,
          deleted,
          disputed,
          proccess: null, // set in the table
          condition: 'type account',
        }
      });
    },
    selectedItem(params){
      this.paramsDetails = {
        process: params.process,
        condition: params.condition,
        valueCondition: params.valueCondition
      }
      this.showModalDetails = true;
    },
    closeDetails(){
      this.showModalDetails = false;
    },
    async selectedFilters(item){
      this.filterParams = item;
      this.typeNcr = item.type;
      this.sourceOption = item.sourceOption;
      this.yearSelected = item.date.year || null;
      this.monthSelected = item.date.month || null;
      await this.getData();
    },
    selectViewClassification(view){
      this.viewClas = view;
    },
    selectViewBureau(view){
      this.viewBureau = view;
    },
    selectViewTypeAccount(view){
      this.viewTypeAcc = view;
    },
  },
};
</script>
