<template>
  <div>
    <b-modal
      v-model="showModal"
      size="xl"
      class=""
      @hidden="closeModal"
      hide-footer
    >
      <template #modal-title>
        <!-- <h5 class="text-uppercase text-white font-weight-bolder m-0 py-50">
          <tabler-icon
            icon="BuildingBankIcon"
            size="18"
            class="mr-1"
          ></tabler-icon>
          <span v-if="!!valuesCondition[condition]" class="mr-50"
            >{{ valuesCondition[condition] }} -
            {{ valuesProcess[process] }} -</span
          >
          DETAILS FOR REMOVED JOURNEY
        </h5> -->
        <div class="m-0 py-50">
          <div class="d-flex align-items-center">
              <tabler-icon
                  icon="BuildingBankIcon"
                  size="18"
                  class="mr-1"
              ></tabler-icon>
              <div class="text-uppercase text-white font-weight-bolder" style="margin-top: 5px;">
                <span v-if="!!valuesCondition[condition]" class="mr-50"
                  >{{ valuesCondition[condition] }} -
                  {{ valuesProcess[process] }} -</span
                >
                DETAILS FOR REMOVED JOURNEY
              </div>
          </div>
          <div>
            <ShowFilters :filters="filters" />
          </div>
      </div>
      </template>
      <b-container fluid class="py-1">
        <b-row>
          <!--lista de los leads-->
          <b-col cols="12" xl="3">
            <div class="d-block">
              <list-leads
                :key="keyLeads"
                :data="dataLeads"
                @selectedItem="selectedLead"
                :paginate="paginateLeads"
                @selectedPage="selectedPage"
                :tab="'removes'"
                :inputSearchLeads="lead_name"
                @search="searchLead"
              />
            </div>
          </b-col>
          <b-col cols="12" xl="9" class="p-1 px-2">
            <!--lista de las cuentas removidas-->
            <div
              class="p-2 rounded-lg h-100"
              :class="isDarkSkin ? 'custom-bg-gray-dark' : 'custom-bg-gray'"
            >
              <h4 class="p-1 text-uppercase font-weight-bolder">
                ITEMS LIST OF LEAD
                <span
                  class="text-primary"
                  v-if="!!currentItemLead.client_name"
                  >{{ currentItemLead.client_name || "" }}</span
                >
              </h4>
              <b-table
                :key="keyTable"
                slot="table"
                ref="tableDetails"
                class="shadow-none custom-heigh"
                :class="isDarkSkin ? 'custom-table-dark' : 'custom-table'"
                :items="items"
                style="box-shadow: none !important"
                :fields="fields"
                :head-row-variant="'grid-custom-tickets-header'"
                :busy.sync="isBusy"
                sticky-header="60vh"
                show-empty
                responsive
                striped
                no-provider-filtering
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading ...</strong>
                  </div>
                </template>
                <template #cell(item)="{ index, item }">
                  <div
                    class="d-flex justify-content-center align-items-center custom-main-td-height"
                  >
                    <span>{{ index + 1 }}</span>
                  </div>
                  <ul class="list-unstyled mt-50" v-if="item.showDetail">
                    <li
                      v-for="(item, index) in textObject.length"
                      :key="index"
                      class="custom-heigh-detail"
                      :class="isDarkSkin ? 'is-dark' : ''"
                    >
                      <!--is empty-->
                    </li>
                  </ul>
                </template>
                <template #cell(account)="{ item }">
                  <div
                    class="d-flex justify-content-center align-items-center custom-main-td-height"
                    style="max-width: 200px"
                  >
                    <div
                      class="d-flex justify-content-center flex-column w-100"
                    >
                      <strong
                        v-b-tooltip.hover.top="item.creditor_name"
                        class="text-truncate text-center"
                        >{{ item.creditor_name }}</strong
                      >
                      <span class="text-center"
                        >Account #:{{ item.account_number }}</span
                      >
                    </div>
                  </div>
                  <ul class="list-unstyled mt-50" v-if="item.showDetail">
                    <li
                      v-for="(item, index) in textObject"
                      :key="index"
                      class="custom-heigh-detail justify-content-center"
                      :class="isDarkSkin ? 'is-dark' : ''"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </template>
                <template #cell(type)="{ item }">
                  <div
                    class="d-flex justify-content-center align-items-center custom-main-td-height"
                  >
                    <span
                      :style="`color: ${textColor[item.classification_ncr_id]}`"
                      >{{ item.classification_ncr }}</span
                    >
                  </div>
                  <ul class="list-unstyled mt-50" v-if="item.showDetail">
                    <li
                      v-for="(item, index) in textObject.length"
                      :key="index"
                      class="custom-heigh-detail"
                      :class="isDarkSkin ? 'is-dark' : ''"
                    >
                      <!--is empty-->
                    </li>
                  </ul>
                </template>
                <template #cell(transunion)="{ item }">
                  <div
                    class="d-flex justify-content-center align-items-center custom-main-td-height"
                  >
                    <!-- {{ item.items[0].account_number || "-" }} -->
                    <template v-if="item.items[0].type_classification_origin">
                      <span
                        :class="colorBadge(item.items, 0, 1)"
                        class="px-1 py-50 rounded-lg w-badge"
                      >
                        {{ item.items[0].type_classification_origin }} -
                        {{ item.items[0].percentage }}%
                        <template
                          v-if="
                            equalsClassification(item.items, 0) &&
                            process !== 'gen by soft'
                          "
                        >
                          <span
                            v-b-tooltip.hover
                            title="current classification"
                            :class="colorBadge(item.items, 0, 2)"
                            class="change_classification rounded-lg"
                            >{{ item.items[0].type_classification }}
                            <feather-icon
                              id="working_hours"
                              icon="InfoIcon"
                              style="margin-left: 5px"
                            />
                          </span>
                        </template>
                      </span>
                    </template>
                    <template v-else>
                      <img
                        :src="require('@/assets/images/icons/transunion.png')"
                        width="30px"
                        height="30px"
                        class="custom-bg-logo"
                        alt="TransUnion"
                      />
                    </template>
                  </div>
                  <ul class="list-unstyled mt-50" v-if="item.showDetail">
                    <li
                      v-for="(key, index) in keyAccounts"
                      :key="index"
                      class="custom-heigh-detail"
                      :class="isDarkSkin ? 'is-dark' : ''"
                      :id="`text_transunion_${index}`"
                    >
                      <template v-if="!!item.items[0][key]">
                        {{
                          item.items[0][key].length > maxLetter
                            ? `${item.items[0][key].slice(0, maxLetter)}...`
                            : item.items[0][key]
                        }}
                        <!--tooltip only greater than 'maxLetter'-->
                        <b-tooltip
                          v-if="item.items[0][key].length > maxLetter"
                          :target="`text_transunion_${index}`"
                        >
                          {{ item.items[0][key] }}
                        </b-tooltip>
                      </template>
                      <template v-else>-</template>
                    </li>
                  </ul>
                </template>
                <template #cell(experian)="{ item }">
                  <div
                    class="d-flex justify-content-center align-items-center custom-main-td-height"
                  >
                    <!-- {{ item.items[0].account_number || "-" }} -->
                    <template v-if="item.items[1].type_classification_origin">
                      <span
                        :class="colorBadge(item.items, 1, 1)"
                        class="px-1 py-50 rounded-lg w-badge"
                      >
                        {{ item.items[1].type_classification_origin }} -
                        {{ item.items[1].percentage }}%
                        <template
                          v-if="
                            equalsClassification(item.items, 1) &&
                            process !== 'gen by soft'
                          "
                        >
                          <span
                            v-b-tooltip.hover
                            title="current classification"
                            :class="colorBadge(item.items, 1, 2)"
                            class="change_classification rounded-lg"
                            >{{ item.items[1].type_classification }}
                            <feather-icon
                              id="working_hours"
                              icon="InfoIcon"
                              style="margin-left: 5px"
                            />
                          </span>
                        </template>
                      </span>
                    </template>
                    <template v-else>
                      <img
                        :src="require('@/assets/images/icons/experian.png')"
                        width="30px"
                        height="30px"
                        class="custom-bg-logo"
                        alt="TransUnion"
                      />
                    </template>
                  </div>
                  <ul class="list-unstyled mt-50" v-if="item.showDetail">
                    <li
                      v-for="(key, index) in keyAccounts"
                      :key="index"
                      class="custom-heigh-detail"
                      :class="isDarkSkin ? 'is-dark' : ''"
                      :id="`text_experian_${index}`"
                    >
                      <template v-if="!!item.items[1][key]">
                        {{
                          item.items[1][key].length > maxLetter
                            ? `${item.items[1][key].slice(0, maxLetter)}...`
                            : item.items[1][key]
                        }}
                        <!--tooltip only greater than 'maxLetter'-->
                        <b-tooltip
                          v-if="item.items[1][key].length > maxLetter"
                          :target="`text_experian_${index}`"
                        >
                          {{ item.items[1][key] }}
                        </b-tooltip>
                      </template>
                      <template v-else>-</template>
                    </li>
                  </ul>
                </template>
                <template #cell(equifax)="{ item }">
                  <div
                    class="d-flex justify-content-center align-items-center custom-main-td-height"
                  >
                    <!-- {{ item.items[0].account_number || "-" }} -->
                    <template v-if="item.items[2].type_classification_origin">
                      <span
                        :class="colorBadge(item.items, 2, 1)"
                        class="px-1 py-50 rounded-lg w-badge"
                      >
                        {{ item.items[2].type_classification_origin }} -
                        {{ item.items[2].percentage }}%
                        <template
                          v-if="
                            equalsClassification(item.items, 2) &&
                            process !== 'gen by soft'
                          "
                        >
                          <span
                            v-b-tooltip.hover
                            title="current classification"
                            :class="colorBadge(item.items, 2, 2)"
                            class="change_classification rounded-lg"
                            >{{ item.items[2].type_classification }}
                            <feather-icon
                              id="working_hours"
                              icon="InfoIcon"
                              style="margin-left: 5px"
                            />
                          </span>
                        </template>
                      </span>
                    </template>
                    <template v-else>
                      <img
                        :src="require('@/assets/images/icons/equifax.png')"
                        width="30px"
                        height="30px"
                        class="custom-bg-logo"
                        alt="TransUnion"
                      />
                    </template>
                  </div>
                  <ul class="list-unstyled mt-50" v-if="item.showDetail">
                    <li
                      v-for="(key, index) in keyAccounts"
                      :key="index"
                      class="custom-heigh-detail"
                      :class="isDarkSkin ? 'is-dark' : ''"
                      :id="`text_equifax_${index}`"
                    >
                      <template v-if="!!item.items[2][key]">
                        {{
                          item.items[2][key].length > maxLetter
                            ? `${item.items[2][key].slice(0, maxLetter)}...`
                            : item.items[2][key]
                        }}
                        <!--tooltip only greater than 'maxLetter'-->
                        <b-tooltip
                          v-if="item.items[2][key].length > maxLetter"
                          :target="`text_equifax_${index}`"
                        >
                          {{ item.items[2][key] }}
                        </b-tooltip>
                      </template>
                      <template v-else>-</template>
                    </li>
                  </ul>
                </template>
                <template #cell(detail)="{ item }">
                  <div
                    class="d-flex justify-content-center align-items-center custom-main-td-height"
                  >
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="20"
                      class="cursor-pointer hover-me hover-me-primary"
                      :class="
                        item.showDetail ? 'text-primary rotate-180' : null
                      "
                      @click="item.showDetail = !item.showDetail"
                    />
                  </div>
                  <ul class="list-unstyled mt-50" v-if="item.showDetail">
                    <li
                      v-for="(item, index) in textObject.length"
                      :key="index"
                      class="custom-heigh-detail"
                      :class="isDarkSkin ? 'is-dark' : ''"
                    >
                      <!-- is empty -->
                    </li>
                  </ul>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import RemoveJourneyServices from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/services/removeJourney.services";
import fields from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/data/detailsAccounts.field";
import ListLeads from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/components/ListLeads.vue";
import ShowFilters from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/ShowFiltersAnalysisCR.vue";

export default {
  components: {
    ListLeads,
    ShowFilters
  },
  props: {
    typeNcr: {
      require: false,
      type: Number,
    },
    source: {
      require: false,
      type: Number,
    },
    year: {
      require: true,
      type: Number,
    },
    month: {
      require: true,
      type: Number,
    },
    process: {
      require: true,
    },
    condition: {
      require: true,
    },
    valueCondition: {
      require: true,
    },
    filters: {
      require: true,
      type: Object,
    },
  },
  computed: {
    keyAccounts() {
      return Object.keys(this.showKeysDetails) || [];
    },
    textObject() {
      return Object.values(this.showKeysDetails) || [];
    },
  },
  data() {
    return {
      showModal: false,
      dataLeads: [],
      items: [],
      leadId: null,
      result_id: null,
      currentItemLead: {},
      keyTable: 0,
      fields,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      textColor: {
        1: "#3498DB",
        2: "#1ABC9C",
        3: "#8E44AD",
        4: "#E74C3C",
        5: "#F1C40F",
        6: "#F39C12",
        7: "#95A5A6",
        8: "#34495E",
        9: "#BA4A00",
        10: "#7F8C8D",
      },
      paginateLeads: {
        current_page: 1,
        per_page: 10,
        total_rows: 0,
      },
      keyLeads: 0,
      showKeysDetails: {
        creditor_name: "Account Name",
        account_number: "Account",
        account_type: "Type",
        account_type_detail: "Type detail",
        bureau_code: "Bureau code",
        account_status: "Account status",
        monthly_payment: "Monthly",
        date_opened: "Date opened",
        balance: "Balance",
        comments: "Comments",
        n_months: "No. Of Months (Terms)",
        credit_limit: "Credit Limit",
        payment_status: "Payment Status",
        last_payment: "Last Payment Status",
        h_credit: "Highest Credit",
      },
      maxLetter: 30,
      classClassification: {
        HIGH: "custom-success",
        MEDIUM: "custom-warning",
        LOW: "custom-danger",
      },
      valuesCondition: {
        total: "general",
        bureau: "bureau",
        classification: "classification",
        "type account": "type account",
      },
      valuesProcess: {
        "gen by soft": "generated by soft",
        "sel by specialist": "selected by specialist",
        "sel by seller": "selected by seller",
        sold: "sold",
        disputed: "disputed",
        "deleted updated": "deleted / update",
        1: "transunion",
        2: "experian",
        3: "equifax",
      },
      lead_name: {
        model: '',
        inputType: "text",
        placeholder: "Search by name...",
        class: "form-control",
      },
    };
  },
  async mounted() {
    this.showModal = true;
    await this.getLeads();
  },
  methods: {
    async getLeads() {
      try {
        const params = {
          leadName: this.lead_name.model,
          process: this.process,
          condition: this.condition,
          valueCondition: this.valueCondition,
          typeNcr: this.typeNcr,
          source: this.source,
          month: this.month,
          year: this.year,
          perpage: this.paginateLeads.per_page,
          page: this.paginateLeads.current_page,
        };
        this.addPreloader();
        const { data } =
          await RemoveJourneyServices.getLeadsWithRemovedAccounts(params);
        this.paginateLeads = {
          current_page: data.current_page,
          per_page: 10,
          total_rows: data.total,
        };
        this.dataLeads = data.data || [];
        if (this.dataLeads.length) {
          this.leadId = this.dataLeads[0].lead_id;
          this.result_id = this.dataLeads[0].id;
          this.currentItemLead = this.dataLeads[0];
        }
        this.keyLeads += 1;
      } catch (error) {
        this.showErrorSwal();
        console.log("error in getLeads modal details: ", error);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    async getListAccounts() {
      try {
        const params = {
          process: this.process,
          condition: this.condition,
          valueCondition: this.valueCondition,
          typeNcr: this.typeNcr,
          source: this.source,
          month: this.month,
          year: this.year,
          leadId: this.leadId,
          result_id: this.result_id,
        };
        this.addPreloader();
        const { data } = await RemoveJourneyServices.getListAccountsByLeadId(
          params
        );
        const newData = data.map((item) => {
          const bureaus = item.items || [];
          return {
            creditor_name: bureaus[0].creditor_name || "",
            account_number: bureaus[0].account_number || "",
            classification_ncr: bureaus[0].classification_ncr || "",
            classification_ncr_id: bureaus[0].classification_ncr_id || "",
            analysis_account_id: bureaus[0].analysis_account_id || "",
            items: this.generateBureaus(bureaus),
            showDetail: false,
          };
        });

        this.totalRows = data.total || 0;
        this.startPage = data.from || 0;
        this.toPage = data.to || 0;

        this.items = newData;
        this.keyTable += 1;
      } catch (error) {
        this.showErrorSwal(error);
        console.log("error in getListAccounts: ", error);
      } finally {
        this.removePreloader();
      }
    },
    generateBureaus(bureaus) {
      const bureausId = bureaus.map((item) => item.bureau_id);
      const noExists = [1, 2, 3].filter((item) => !bureausId.includes(item));

      const objBureau = Object.keys(bureaus[0]).reduce((array, item) => {
        array[item] = null;
        return array;
      }, {});
      const othersBureaus = noExists.map((id) => {
        const item = { ...objBureau };
        item.bureau_id = id;
        return item;
      });

      return [...bureaus, ...othersBureaus].sort(
        (a, b) => a.bureau_id - b.bureau_id
      );
    },
    selectedLead(item) {
      this.leadId = item.lead_id;
      this.result_id = item.id;
      this.currentItemLead = item;
    },
    async selectedPage(page) {
      this.paginateLeads.current_page = page;
      await this.getLeads();
    },
    async searchLead(item) {
      this.lead_name.model = item;
      await this.getLeads();
    },
    colorBadge(items, position, property) {
      let props = { 1: "type_classification_origin", 2: "type_classification" };
      let color = this.classClassification[items[position][props[property]]];
      return `${color}`;
    },
    equalsClassification(items, position) {
      return (
        items[position].type_classification_origin !=
        items[position].type_classification
      );
    },
  },
  watch: {
    async result_id(newValue) {
      if (newValue) {
        await this.getListAccounts();
      }
    },
  },
};
</script>
<style scoped>
* {
  --gray: #fafafa;
}
.custom-list {
  max-height: 500px !important;
  overflow-y: auto !important;
}
.hover-me-primary {
  transition: 0.4s ease-in-out !important;
}
.hover-me-primary:hover {
  color: #007deb !important;
}
.custom-table,
.custom-table-dark {
  border-radius: 10px !important;
}
.custom-table >>> thead tr th,
.custom-table-dark >>> thead tr th {
  background-color: #0090e7 !important;
}
.custom-table >>> tbody tr {
  background-color: white !important;
}
.custom-bg-gray {
  background-color: var(--gray) !important;
}
.custom-bg-gray-dark {
  background-color: #2f2f31 !important;
}
.custom-heigh {
  height: 450px !important;
  overflow: auto;
}
.custom-main-td-height {
  height: 80px;
  display: flex;
  align-items: center !important;
}
.custom-heigh-detail {
  width: 100% !important;
  height: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid white !important;
  background-color: var(--gray) !important;
}
.is-dark {
  border-top: 1px solid #2f2f31 !important;
  background-color: transparent !important;
}
.rotate-180 {
  transition: 0.3s ease-in-out;
  transform: rotate(180deg);
}
.custom-success {
  color: white !important;
  font-weight: bolder !important;
  background-color: #00f380 !important;
}
.custom-warning {
  color: white !important;
  background-color: #ffaf00 !important;
}
.custom-danger {
  color: white !important;
  font-weight: bolder !important;
  background-color: #fe0000 !important;
}
.w-badge {
  width: auto !important;
  position: relative;
  text-align: left;
}
.custom-main-td-height {
  height: 80px;
  display: flex;
  align-items: center !important;
}
.change_classification {
  width: 100px;
  height: 25px;
  text-align: center;
  position: absolute;
  bottom: 70%;
  left: 70%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
