<template>
  <b-card class="px-1 py-50 rounded-lg">
    <div
      class="border-bottom-primary border-2 pb-50 d-flex flex-wrap justify-content-between align-items-center"
    >
      <h4 class="font-weight-bolder text-uppercase">
        for account type
        <img
          width="22"
          height="22"
          src="https://img.icons8.com/external-bluetone-bomsymbols-/91/external-atom-digital-design-bluetone-set-2-bluetone-bomsymbols-.png"
          alt="external-atom-digital-design-bluetone-set-2-bluetone-bomsymbols-"
          class="ml-50"
        />
      </h4>
      <div class="d-flex w-auto">
        <b-button
          @click="typeView = 'list'"
          :variant="typeView == 'list' ? 'primary' : 'outline-primary'"
          class="mr-50 p-50"
        >
          <feather-icon icon="ListIcon" size="18" />
        </b-button>
        <b-button
          @click="typeView = 'graphic'"
          :variant="typeView == 'graphic' ? 'primary' : 'outline-primary'"
          class="p-50"
        >
          <feather-icon icon="BarChart2Icon" size="18" />
        </b-button>
      </div>
    </div>
    <b-row class="pt-2 mt-50">
      <b-col cols="12" style="overflow-x: auto !important">
        <b-skeleton-table
          v-if="isLoading"
          :columns="6"
          :rows="8"
          width="100%"
          height="2rem"
          animated
        >
        </b-skeleton-table>
        <!-- statistical list -->
        <b-table
          v-if="typeView == 'list' && !isLoading"
          :fields="fields"
          :items="items"
          :class="isDarkSkin ? 'custom-table-dark' : 'custom-table'"

        >
          <template #cell(item)="{ item }">
            <div
              class="d-flex align-items-center w-100 py-2 px-2 custom-td-item font-weight-bolder"
            >
              {{ item.item }}
            </div>
          </template>
          <template #cell(by_soft)="{ item }">
            <div
              class="d-flex justify-content-center align-items-center w-100 py-2 px-2 custom-td-item text-center font-size-md"
              :class="!!item.by_soft ? 'hoverable-row' : null"
              @click="!!item.by_soft ? sendParams(item, 'gen by soft') : null"
            >
              <span>{{ item.by_soft }}</span>
            </div>
          </template>
          <template #cell(by_specialists)="{ item }">
            <div
              class="d-flex justify-content-center align-items-center w-100 py-2 px-2 custom-td-item text-center font-size-md"
              :class="!!item.by_specialists ? 'hoverable-row' : null"
              @click="!!item.by_specialists ? sendParams(item, 'sel by specialist') : null"
            >
              <span>{{ item.by_specialists }}</span>
            </div>
          </template>
          <template #cell(by_seller)="{ item }">
            <div
              class="d-flex justify-content-center align-items-center w-100 py-2 px-2 custom-td-item text-center font-size-md"
              :class="!!item.by_seller ? 'hoverable-row' : null"
              @click="!!item.by_seller ? sendParams(item, 'sel by seller') : null"
            >
              <span>{{ item.by_seller }}</span>
            </div>
          </template>
           <template #cell(sold)="{ item }">
            <div
              class="d-flex justify-content-center align-items-center w-100 py-2 px-2 custom-td-item text-center font-size-md"
              :class="!!item.sold ? 'hoverable-row' : null"
              @click="!!item.sold ? sendParams(item, 'sold') : null"
            >
              <span>{{ item.sold }}</span>
            </div>
          </template>
          <template #cell(disputed)="{ item }">
            <div
              class="d-flex justify-content-center align-items-center w-100 py-2 px-2 custom-td-item text-center font-size-md"
              :class="!!item.disputed ? 'hoverable-row' : null"
              @click="!!item.disputed ? sendParams(item, 'disputed'): null"
            >
              <span>{{ item.disputed }}</span>
            </div>
          </template>
          <template #cell(deleted)="{ item }">
            <div
              class="d-flex justify-content-center align-items-center w-100 py-2 px-2 custom-td-item text-center font-size-md"
              :class="!!item.deleted ? 'hoverable-row' : null"
              @click="!!item.deleted ? sendParams(item, 'deleted updated') : null"
            >
              <span>{{ item.deleted }}</span>
            </div>
          </template>
        </b-table>
        <!--statistical graphic -->
        <div v-else-if="!isLoading" class="d-block">
          <graphics-components :xAxisData="xAxisData" :yAxisData="yAxisData" @selectItemChart="selectItemChart" />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import fields from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/data/forAccountType.fields";
import GraphicsComponents from '@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/components/Graphics.vue';

export default {
  components:{
    GraphicsComponents
  },
  props: {
    items: {
      require: true,
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    viewDefault: {
      type: String,
      default: 'list',
      required: false,
    },
  },
  data() {
    return {
      fields,
      typeView: "list",
      xAxisData: [],
      yAxisData: {},
    };
  },
  methods: {
    sendParams({ item, condition }, process) {
      const params = { process, condition, valueCondition: item };
      this.$emit("selectedItem", params);
    },
    generateChart(){

      const seriesA = this.items.map(item => item.by_soft);
      const seriesB = this.items.map(item => item.by_specialists);
      const seriesC = this.items.map(item => item.by_seller);
      const seriesD = this.items.map(item => item.sold);
      const seriesE = this.items.map(item => item.disputed);
      const seriesF = this.items.map(item => item.deleted);

      const sumA = seriesA.reduce((sum, item) => {
        return sum += item;
      },0);
      const sumB = seriesB.reduce((sum, item) => {
        return sum += item;
      },0);
      const sumC = seriesC.reduce((sum, item) => {
        return sum += item;
      },0);
      const sumD = seriesD.reduce((sum, item) => {
        return sum += item;
      },0);
      const sumE = seriesE.reduce((sum, item) => {
        return sum += item;
      },0);
      const sumF = seriesF.reduce((sum, item) => {
        return sum += item;
      },0);

      // x axis data
      const xAxisData = [
        {name:'by soft' , total: sumA },
        {name:'by specialists', total: sumB },
        {name:'by seller' , total: sumC },
        {name:'sold' , total: sumD },
        {name:'disputed' , total: sumE },
        {name:'deleted / updated' , total: sumF }];
      this.xAxisData = xAxisData;

      // y data
      const yData = this.items.map(item => item.item);
      let data = [];
      yData.forEach((item, index) => {
        data[item] = [seriesA[index], seriesB[index], seriesC[index], seriesD[index], seriesE[index], seriesF[index]];
      });
      this.yAxisData = {...data };
    },
    selectItemChart({xAxisIndex, yAxisIndex}){
      const keyProcess = {
        0: 'gen by soft',
        1: 'sel by specialist',
        2: 'sel by seller',
        3: 'sold',
        4: 'disputed',
        5: 'deleted updated',
      };
      const { item, condition } = this.items[yAxisIndex];
      const process = keyProcess[xAxisIndex];

      this.sendParams({item, condition}, process)

    }
  },
  mounted(){
    if(!!this.viewDefault){
      this.typeView = this.viewDefault;
    }
  },
  watch:{
    typeView(newValue){
      if(newValue){
        this.generateChart();
      }
      this.$emit('selectView', newValue);
    },
  }
};
</script>
<style scoped>
*{
  --gray: #717d7e8e;
  --blue: #0090e7;
}
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th,
.custom-table-dark >>> thead tr th,
.custom-table-dark >>> tfoot tr th {
  background-color: #0090e7 !important;
  border-style: none !important;
  color: white !important;
}
.custom-table >>> thead tr th:first-child,
.custom-table-dark >>> thead tr th:first-child {
  border-top-left-radius: 10px !important;
}
.custom-table >>> thead tr th:last-child,
.custom-table-dark >>> thead tr th:last-child {
  border-top-right-radius: 10px !important;
}
.custom-table >>> tbody tr td .custom-td-item {
  background-color: #fafafa !important;
  height: 70px !important;
}
.custom-table-dark >>> tbody tr td .custom-td-item {
  background-color: transparent !important;
  height: 70px !important;
}
.custom-table >>> tbody tr td,
.custom-table-dark >>> tbody tr td {
  border-style: none !important;
  padding: 3px 0px !important;
}
.custom-table >>> tbody tr:first-child td,
.custom-table-dark >>> tbody tr:first-child td {
  padding-top: 8px !important;
}
.custom-table >>> tbody tr td:first-child .custom-td-item,
.custom-table-dark >>> tbody tr td:first-child .custom-td-item {
  border-radius: 10px 0px 0px 10px !important;
  background-color: #d5effa !important;
}
.custom-table-dark >>> tbody tr td:first-child .custom-td-item {
  border: 1px solid #2980b9 !important;
}
.custom-table >>> tbody tr td:last-child .custom-td-item,
.custom-table-dark >>> tbody tr td:last-child .custom-td-item {
  border-radius: 0px 10px 10px 0px !important;
}
.custom-table-dark >>> tbody tr td .custom-td-item {
  border-top: 1px solid var(--gray) !important;
  border-bottom: 1px solid var(--gray) !important;
}
.custom-table-dark >>> tbody tr td:last-child .custom-td-item {
  border-right: 1px solid var(--gray) !important;
}

.custom-table-dark >>> tbody tr td {
  background-color: transparent !important;
}

.custom-table-dark >>> tbody tr td:first-child .custom-td-item {
  background-color: #2980b9 !important;
  color: white;
}
.text-underline {
  text-decoration: underline !important;
}
.custom-bg-gray {
  background-color: #fafafa !important;
}
.custom-bg-gray-dark {
  background-color: #2f2f31 !important;
}
.font-size-md {
  font-size: 14px;
}
.hoverable-row:hover{
  box-shadow: rgba(0, 144, 231, 0.3) 0px 30px 60px -12px inset,
    rgba(0, 144, 231, 0.3) 0px 18px 36px -18px inset;
  transition: box-shadow 0.3s ease !important;
  cursor: pointer;
}
</style>
