<template>
  <b-card class="px-1 py-50 rounded-lg">
    <div
      class="border-bottom-primary border-2 pb-50 d-flex flex-wrap justify-content-between align-items-center"
    >
      <h4 class="font-weight-bolder text-uppercase">
        for classification
        <img
          width="22"
          height="22"
          src="https://img.icons8.com/external-bluetone-bomsymbols-/91/external-general-general-office-bluetone-bluetone-bomsymbols--8.png"
          alt="external-general-general-office-bluetone-bluetone-bomsymbols--8"
          class="ml-50"
        />
      </h4>
      <!-- OPTIONS -->
      <div class="d-flex w-auto">
        <b-button
          @click="typeView = 'list'"
          :variant="typeView == 'list' ? 'primary' : 'outline-primary'"
          class="mr-50 p-50"
        >
          <feather-icon icon="ListIcon" size="18" />
        </b-button>
        <b-button
          @click="typeView = 'graphic'"
          :variant="typeView == 'graphic' ? 'primary' : 'outline-primary'"
          class="p-50"
        >
          <feather-icon icon="BarChart2Icon" size="18" />
        </b-button>
      </div>
    </div>
    <b-row class="pt-2 mt-50">
      <b-col cols="12" style="overflow-x: auto !important;">
        <b-skeleton-table
          v-if="isLoading"
          :columns="5"
          :rows="5"
          width="100%"
          animated
        >
        </b-skeleton-table>
        <!-- statistical list -->
        <b-table
          v-else-if="typeView == 'list' && !isLoading"
          :fields="fields"
          :items="items"
          :class="isDarkSkin ? 'custom-table-dark' : 'custom-table'"
        >
          <template #cell(item)="{ item }">
            <div class="d-flex align-items-center w-100 py-2 px-2 custom-td-item font-weight-bolder">
              {{ item.item }}
            </div>
          </template>
          <template #cell(high)="{ item }">
            <div
              @click="!!item.high ? sendParams(item,'high'): null"
              :class="!!item.high ? 'hoverable-row' : null"
              class="d-flex justify-content-center align-items-center w-100 py-2 px-2 custom-td-item text-center font-size-md"
              >
              <span>{{ item.high }}</span>
            </div>
          </template>
          <template #cell(medium)="{ item }">
            <div
              @click="!!item.medium ? sendParams(item,'medium') : null"
              :class="!!item.medium ? 'hoverable-row' : null"
              class="d-flex justify-content-center align-items-center w-100 py-2 px-2 custom-td-item text-center font-size-md"
              >
              <span>{{ item.medium }}</span>
            </div>
          </template>
          <template #cell(low)="{ item }">
            <div
              @click="!!item.low ? sendParams(item,'low') : null"
              :class="!!item.low ? 'hoverable-row' : null"
              class="d-flex justify-content-center align-items-center w-100 py-2 px-2 custom-td-item text-center font-size-md"
              >
              <span>{{ item.low }}</span>
            </div>
          </template>
          <template #cell(total)="{ item }">
            <div
              @click="!!item.total ? sendParams(item,null) : null"
              :class="!!item.total ? 'hoverable-row' : null"
              class="d-flex justify-content-center align-items-center w-100 py-2 px-2 custom-td-item text-center font-size-md"
              >
              <span>{{ item.total }}</span>
            </div>
          </template>
        </b-table>
        <!--statistical graphic -->
        <div v-else-if="!isLoading" class="d-block">
          <graphics-components :xAxisData="xAxisData" :yAxisData="yAxisData" @selectItemChart="selectItemChart" />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import fields from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/data/forClassification.fields";
import GraphicsComponents from '@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/components/Graphics.vue';

export default {
  components: {
    GraphicsComponents,
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    isLoading:{
      type: Boolean,
      default: false,
    },
    viewDefault: {
      type: String,
      default: 'list',
      required: false,
    }
  },
  data() {
    return {
      fields,
      typeView: "list",
      xAxisData: [],
      yAxisData: {},
    };
  },
  methods: {
    sendParams({ process, condition }, valueCondition) {
      const params = { process, condition, valueCondition };
      this.$emit("selectedItem", params);
    },
    generateChart(){
      const seriesA = this.items.map((item) => item.high);
      const seriesB = this.items.map((item) => item.medium);
      const seriesC = this.items.map((item) => item.low);

      // params to graphic
      this.yAxisData = {
        high: seriesA,
        medium: seriesB,
        low: seriesC,
      };

      // const xAxisData = this.items.map((item) => item.item);
      const xAxisData = ['by soft','by specialists','by seller','sold', 'disputed','deleted / updated'];
      const newXAxisData = xAxisData.map((item, index) => {
        let total = parseInt(seriesA[index]) + parseInt(seriesB[index]) + parseInt(seriesC[index]);
        return {
          name: item,
          total
        }
      });
      this.xAxisData = newXAxisData;
    },
    selectItemChart({xAxisIndex, yAxisIndex }){
      const keysChart = Object.keys(this.yAxisData);
      const { process, condition } = this.items[xAxisIndex];
      const valueCondition = keysChart[yAxisIndex] || null
      this.sendParams({process, condition},valueCondition);
    }
  },
  mounted(){
    if(!!this.viewDefault){
      this.typeView = this.viewDefault;
    }
  },
  watch:{
    typeView(newValue){
      if(newValue){
        this.generateChart();
      }
      this.$emit('selectView', newValue)
    },
  }
};
</script>
<style scoped>
*{
  --gray: #717d7e8e;
  --blue: #0090e7;
}
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th,
.custom-table-dark >>> thead tr th,
.custom-table-dark >>> tfoot tr th {
  background-color: #0090e7 !important;
  border-style: none !important;
  color: white !important;
}
.custom-table >>> thead tr th:first-child,
.custom-table-dark >>> thead tr th:first-child {
  border-top-left-radius: 10px !important;
}
.custom-table >>> thead tr th:last-child,
.custom-table-dark >>> thead tr th:last-child {
  border-top-right-radius: 10px !important;
}
.custom-table >>> tbody tr td .custom-td-item {
  background-color: #fafafa !important;
  height: 70px !important;
}
.custom-table-dark >>> tbody tr td .custom-td-item {
  background-color: transparent !important;
  height: 70px !important;
}
.custom-table >>> tbody tr td,
.custom-table-dark >>> tbody tr td {
  border-style: none !important;
  padding: 3px 0px !important;
}
.custom-table >>> tbody tr:first-child td,
.custom-table-dark >>> tbody tr:first-child td {
  padding-top: 8px !important;
}
.custom-table >>> tbody tr td:first-child .custom-td-item,
.custom-table-dark >>> tbody tr td:first-child .custom-td-item {
  border-radius: 10px 0px 0px 10px !important;
  background-color: #d5effa !important;
}
.custom-table-dark >>> tbody tr td .custom-td-item {
  border-top: 1px solid var(--gray) !important;
  border-bottom: 1px solid var(--gray) !important;
}
.custom-table-dark >>> tbody tr td:last-child .custom-td-item{
  border-right: 1px solid var(--gray) !important;
}
.custom-table-dark >>> tbody tr td:last-child .custom-td-item {
  border: 1px solid #717d7e !important;
}
.custom-table >>> tbody tr td:last-child .custom-td-item,
.custom-table-dark >>> tbody tr td:last-child .custom-td-item {
  border-radius: 0px 10px 10px 0px !important;
}

.custom-table >>> tbody tr td:last-child,
.custom-table-dark >>> tbody tr td:last-child{
  border-left: 2px solid var(--blue) !important;
  border-right: 2px solid var(--blue) !important;
}
.custom-table >>> tbody tr:last-child td:last-child,
.custom-table-dark >>> tbody tr:last-child td:last-child{
  border-bottom: 2px solid var(--blue) !important;
  border-radius: 10px !important;
}

.custom-table-dark >>> tbody tr td {
  background-color: transparent !important;
}
.custom-table-dark >>> tbody tr td:first-child .custom-td-item {
  background-color: #2980b9 !important;
  color: white;
}
.text-underline {
  text-decoration: underline !important;
}
.custom-bg-gray {
  background-color: #fafafa !important;
}
.custom-bg-gray-dark {
  background-color: #2f2f31 !important;
}
.font-size-md {
  font-size: 14px;
}
.hoverable-row:hover{
  box-shadow: rgba(0, 144, 231, 0.3) 0px 30px 60px -12px inset,
    rgba(0, 144, 231, 0.3) 0px 18px 36px -18px inset;
  transition: box-shadow 0.3s ease !important;
  cursor: pointer;
}
</style>
