export default [
    {
        key: 'item',
        label: 'account type',
        thClass: 'py-1',
        tdClass: 'py-0 px-0 text-primary',
    },
    {
        key: 'by_soft',
        label: 'by soft',
        thClass: 'py-1 text-center',
        tdClass: 'py-0 px-0',
    },
    {
        key: 'by_specialists',
        label: 'by specialist',
        thClass: 'py-1 text-center',
        tdClass: 'py-0 px-0',
    },
    {
        key: 'by_seller',
        label: 'by seller',
        thClass: 'py-1 text-center',
        tdClass: 'py-0 px-0',
    },
    {
      key: 'sold',
      label: 'sold',
      thClass: 'py-1 text-center',
      tdClass: 'py-0 px-0',
    },
    {
        key: 'disputed',
        label: 'disputed',
        thClass: 'py-1 text-center',
        tdClass: 'py-0 px-0',
    },
    {
        key: 'deleted',
        label: 'deleted',
        thClass: 'py-1 text-center',
        tdClass: 'py-0 px-0',
    },
];
