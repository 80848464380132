<template>
  <b-card class="px-1 py-50 rounded-lg">
    <div class="border-bottom-primary border-2 pb-50">
      <h4
        class="font-weight-bolder text-uppercase d-flex justify-content-start align-items-center"
      >
        general remove journey
        <img
          width="22"
          height="22"
          src="https://img.icons8.com/external-bluetone-bomsymbols-/91/external-general-general-office-bluetone-bluetone-bomsymbols--4.png"
          alt="external-general-general-office-bluetone-bluetone-bomsymbols--4"
          class="img-fluid ml-50"
        />
      </h4>
    </div>
    <b-row class="pt-2 mt-50">
      <b-col cols="12" lg="3" style="overflow-x: auto !important">
        <b-skeleton-table
          v-if="isLoading"
          :columns="2"
          :rows="5"
          width="100%"
          animated
        >
        </b-skeleton-table>
        <b-table
          v-else
          :fields="fields"
          :items="items"
          :class="isDarkSkin ? 'custom-table-dark' : 'custom-table'"
        >
          <template #cell(item)="{ item }">
            <div
              class="d-flex align-items-center font-weight-bolder w-100 py-2 px-2 custom-td-item"
            >
              {{ item.item }}
            </div>
          </template>
          <template #cell(quantity)="{ item }">
            <div
              class="d-flex justify-content-center align-items-center w-100 py-2 px-2 custom-td-item text-center font-size-md"
              :class="!!item.quantity ? 'hoverable-row': null"
              @click="!!item.quantity ? sendParams(item): null"
            >
              <span>{{ item.quantity }}</span>
            </div>
          </template>
        </b-table>
      </b-col>
      <b-col cols="12" lg="9" class="px-50">
          <b-skeleton-img v-if="isLoading" height="400px"></b-skeleton-img>
          <e-charts
            v-else
            style="width: 100% !important; height: 420px !important"
            :key="keyChart"
            ref="line"
            auto-resize
            autoresize
            :options="option"
            class="rounded-lg"
            :class="!isDarkSkin ? 'custom-bg-gray' : 'custom-bg-gray-dark'"
            @click="handleChartClick"
          />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import fields from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/data/general.fields";
import echarts from "echarts/lib/echarts";
import ECharts from "vue-echarts";

export default {
  components: {
    ECharts,
  },
  props: {
    items: {
      require: true,
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields,
      keyChart: 0,
      option: {
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: [],
            axisLabel: {
              color: "#fff",
              margin: 14,
              fontSize: 10,
              interval: 0,
              rotate: 15
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLabel: {
              margin: 20,
              fontSize: 14,
              color: "#fff",
            },
            axisLine: { show: true },
          },
        ],
        series: [
          {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#83bff6" }, // Color inicial
              { offset: 0.5, color: "#188df0" }, // Color medio
              { offset: 1, color: "#188df0" }, // Color final
            ]),
            type: "bar",
            barGap: 0,
            label: {
              show: true,
              position: "top",
              distance: 12,
              align: "center",
              verticalAlign: "middle",
              rotate: 1,
              formatter: "{c}",
              fontSize: 12,
              color: "#fff",
            },
            itemStyle: {
              normal: {
                barBorderRadius: [7, 7, 0, 0],
              },
            },
            data: [],
            showBackground: true,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.4)",
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#2378f7" },
                  { offset: 0.7, color: "#2378f7" },
                  { offset: 1, color: "#83bff6" },
                ]),
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    sendParams({ process, condition }) {
      const params = { process, condition, valueCondition: null };
      this.$emit("selectedItem", params);
    },
    handleChartClick({ dataIndex }) {
      const { process, condition } = this.items[dataIndex];
      const params = { process, condition, valueCondition: null };
      this.$emit("selectedItem", params);
    },
    generateChart() {
      const xAxisData = this.items.map((item) => item.item);
      const series = this.items.map((item) => item.quantity);
      this.option.xAxis[0].data = xAxisData;
      this.option.series[0].data = series;
      this.keyChart += 1;
    },
    setInitialTextColor() {
      this.option.series[0].label.color = this.isDarkSkin ? "#fff" : "#000";
      this.option.xAxis[0].axisLabel.color = this.isDarkSkin ? "#fff" : "#000";
      this.option.yAxis[0].axisLabel.color = this.isDarkSkin ? "#fff" : "#000";
      this.keyChart += 1;
    },
  },
  mounted() {
    this.setInitialTextColor();
    this.generateChart();
  },
  watch: {
    isDarkSkin(value) {
      this.option.series[0].label.color = value ? "#fff" : "#000";
      this.option.xAxis[0].axisLabel.color = value ? "#fff" : "#000";
      this.option.yAxis[0].axisLabel.color = value ? "#fff" : "#000";
      this.keyChart += 1;
    },
  },
};
</script>
<style scoped>
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th,
.custom-table-dark >>> thead tr th,
.custom-table-dark >>> tfoot tr th {
  background-color: #0090e7 !important;
  border-style: none !important;
  color: white !important;
}
.custom-table >>> thead tr th:first-child,
.custom-table-dark >>> thead tr th:first-child {
  border-top-left-radius: 10px !important;
}
.custom-table >>> thead tr th:last-child,
.custom-table-dark >>> thead tr th:last-child {
  border-top-right-radius: 10px !important;
}
.custom-table >>> tbody tr td .custom-td-item {
  background-color: #fafafa !important;
  height: 70px !important;
}
.custom-table-dark >>> tbody tr td .custom-td-item {
  background-color: transparent !important;
  height: 70px !important;
}
.custom-table >>> tbody tr td,
.custom-table-dark >>> tbody tr td {
  border-style: none !important;
  padding: 3px 0px !important;
}
.custom-table >>> tbody tr:first-child td,
.custom-table-dark >>> tbody tr:first-child td {
  padding-top: 8px !important;
}
.custom-table >>> tbody tr td:first-child .custom-td-item,
.custom-table-dark >>> tbody tr td:first-child .custom-td-item {
  border-radius: 10px 0px 0px 10px !important;
  background-color: #d5effa !important;
}
.custom-table-dark >>> tbody tr td:first-child .custom-td-item {
  border: 1px solid #2980b9 !important;
}
.custom-table >>> tbody tr td:last-child .custom-td-item,
.custom-table-dark >>> tbody tr td:last-child .custom-td-item {
  border-radius: 0px 10px 10px 0px !important;
}
.custom-table-dark >>> tbody tr td:last-child .custom-td-item {
  border: 1px solid #717d7e !important;
}

.custom-table-dark >>> tbody tr td {
  background-color: transparent !important;
}
.custom-table-dark >>> tbody tr td:first-child .custom-td-item {
  background-color: #2980b9 !important;
  color: white;
}
.text-underline {
  text-decoration: underline !important;
}
.custom-bg-gray {
  background-color: #fafafa !important;
}
.custom-bg-gray-dark {
  background-color: #2f2f31 !important;
}
.font-size-md {
  font-size: 14px;
}
.hoverable-row:hover{
  box-shadow: rgba(0, 144, 231, 0.3) 0px 30px 60px -12px inset,
    rgba(0, 144, 231, 0.3) 0px 18px 36px -18px inset;
  transition: box-shadow 0.3s ease !important;
  cursor: pointer;
}
</style>
