export default [
    {
        key: 'item',
        label: 'Account type',
        thClass: 'py-1',
        tdClass: 'py-0 px-0 text-primary',
    },
    {
        key: 'high',
        label: 'high',
        thClass: 'py-1 text-center',
        tdClass: 'py-0 px-0',
    },
    {
        key: 'medium',
        label: 'medium',
        thClass: 'py-1 text-center',
        tdClass: 'py-0 px-0',
    },
    {
        key: 'low',
        label: 'low',
        thClass: 'py-1 text-center',
        tdClass: 'py-0 px-0',
    },
    {
        key: 'total',
        label: 'total',
        thClass: 'py-1 text-center',
        tdClass: 'py-0 px-0',
    }
];