export default [
    {
        key: 'item',
        label: 'account type',
        thClass: 'py-1',
        tdClass: 'py-0 px-0 text-primary',
    },
    {
        key: 'transunion',
        label: 'transunion',
        thClass: 'py-1 text-center',
        tdClass: 'py-0 px-0',
    },
    {
        key: 'experian',
        label: 'experian',
        thClass: 'py-1 text-center',
        tdClass: 'py-0 px-0',
    },
    {
        key: 'equifax',
        label: 'equifax',
        thClass: 'py-1 text-center',
        tdClass: 'py-0 px-0',
    },
    {
        key: 'total',
        label: 'total',
        thClass: 'py-1 text-center',
        tdClass: 'py-0 px-0',
    }
];