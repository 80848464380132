<template>
  <b-card class="px-1 py-50 rounded-lg">
    <div
      class="border-bottom-primary border-2 pb-50 d-flex flex-wrap justify-content-between align-items-center"
    >
      <h4 class="font-weight-bolder text-uppercase">
        for bureau
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAEH0lEQVR4nKWV/U9bZRTHm5lodIkxxt81Mdk/4e/YF0p7b9eFRbeMCUZkFeh9bktbei8tZWYjMEANKffedry4vlKgo4y3USisXXHd5H1jMieCWxyjlJcyFsxjniatvQENmyf5JifnPOdzzz15XgSCfzGl0v2GXMPgmIbrwgE7iZP2SZy0RZGvIDkvBjiMpuljglexXNr6Dg64OzKt85qIHlHnVE0UiSqHCSTki42DQKZ1uBSAjeFlzCdHgsrVbKlU57bkVEUKcKO/qcjifFxW2xm3MAMJC9ufKK/zrRdaXI/l1PVGIRVU4aQ9rABcyX9CcbX9HEZeDYsMYyUFJvf9ZndoZy2+Cff29uCz9URKyEexZs/4doHJuSA2jn4t17TexAn7mUOhMm3zRxhpu5dTFSo+b3b89tPso78QJC3/6FRK2bHJmV/30VpUgwMuhhPshwe7BZwjV++/dNbsHR2OLLxMFyeTu/Dy1UFY0dQNtY3dsLZ1CCZ3dzPwwfD8yzNVnSO5xj4zpmHaeNDPVO3v4qTtjogaKVXXd21kdzUYnoPXx6bg8O35lHpD03AoMs/rvLzOtyGmR79BXX9OtB3PgDG1NU9ecc2bT/l+HIrM7WcXdQSicO6XlQz4z+cJGIot8sADt+b2841dHWgXYQQryRoDY8g1dF8prPHeXX6yxiuafrAM69uHYf+t2RQ4O5fW8h9rsPCiNybVdzXgJEf8Aya4Wqm+p7bI4nkYT2wdKByZvA9LL3sgqPfB29NLB/LrG1sQ1ebp/Bcxgr2UAZ8k2cpcg/9KUY333srT54d2lZ7vt7Z+OL+0yu/4yRpEfyvR99Sf1Nh0GbACtMhkGlfrKdrnHIku8GYcmVqCa+uJzIxnFn9PzZ330cj8fj7lc8i0jg7ejJXlzPsYaQ8LqZvlZEMPb1f0jc/AgfBcBtw/MQtvTMzwwGRD94bQGCzDgS0qK7W/x9tyGGA7JZUB07lqT3j87mKm6+2dJLQwfdD4gz+l6pa+VCydH4s92D9r9kyIDf0UBmyuQ+6IlhNyYP9ZRAe/KjQ7V2cermRO3osXe9AzFEsJ+ek4WvOF2bEqNASL0e0nB9aPDz3WGLCr5Jq2ITEVVBWYnI/ae6PJze2dFOTps3hKyN/c2oGtvdHkeZNzSUiFVJimI4ADpvhQaNoUwGbCyNZgjnHsgtLYzX5Z41rVfe+PNzlGN5F03/njRRbXKsoJqVAxpmkbRrtKcBRTgBYZRrIL0goXg67PT6mxEon+hhFJRAcvoFiezmPFARs7RTJiwauYUNX4lgKwVpnWYUOgbMkr3Hac4FqUtPtNwesYTdPHcILpkVZ0NaShEl1PnYLgAujpei1o2pTldW9jBDch0QeqpfqASUGwEfR0/S9o2k6rrR/gBBdGQv5Riv4Gr8WcW3eVoykAAAAASUVORK5CYII=">
      </h4>
      <div class="d-flex w-auto">
        <b-button
          @click="typeView = 'list'"
          :variant="typeView == 'list' ? 'primary' : 'outline-primary'"
          class="mr-50 p-50"
        >
          <feather-icon icon="ListIcon" size="18" />
        </b-button>
        <b-button
          @click="typeView = 'graphic'"
          :variant="typeView == 'graphic' ? 'primary' : 'outline-primary'"
          class="p-50"
        >
          <feather-icon icon="BarChart2Icon" size="18" />
        </b-button>
      </div>
    </div>
    <b-row class="pt-2 mt-50">
      <b-col cols="12" style="overflow-x: auto !important">
        <b-skeleton-table
          v-if="isLoading"
          :columns="5"
          :rows="5"
          width="100%"
          animated
        >
        </b-skeleton-table>
        <!-- statistical list -->
        <b-table
          v-else-if="typeView == 'list' && !isLoading"
          :fields="fields"
          :items="items"
          :class="isDarkSkin ? 'custom-table-dark' : 'custom-table'"
        >
          <template #cell(item)="{ item }">
            <div
              class="d-flex align-items-center w-100 py-2 px-2 custom-td-item font-weight-bolder"
            >
              {{ item.item }}
            </div>
          </template>
          <template #cell(transunion)="{ item }">
            <div
              class="d-flex justify-content-center align-items-center w-100 py-2 px-2 custom-td-item text-center font-size-md"
              :class="!!item.transunion ? 'hoverable-row':null"
              @click="item.transunion ? sendParams(item, 1): null"
            >
              <span>{{ item.transunion }}</span>
            </div>
          </template>
          <template #cell(experian)="{ item }">
            <div
              class="d-flex justify-content-center align-items-center w-100 py-2 px-2 custom-td-item text-center font-size-md"
              :class="!!item.experian ? 'hoverable-row':null"
              @click="!!item.experian ? sendParams(item, 2) : null"
            >
              <span>{{ item.experian }}</span>
            </div>
          </template>
          <template #cell(equifax)="{ item }">
            <div
              class="d-flex justify-content-center align-items-center w-100 py-2 px-2 custom-td-item text-center font-size-md"
              :class="!!item.equifax ? 'hoverable-row':null"
              @click="!!item.equifax ? sendParams(item, 3) : null"
            >
              <span>{{ item.equifax }}</span>
            </div>
          </template>
          <template #cell(total)="{ item }">
            <div
              class="d-flex justify-content-center align-items-center w-100 py-2 px-2 custom-td-item text-center"
              :class="!!item.equifax ? 'hoverable-row':null"
              @click="!!item.total ? sendParams(item, null): null"
            >
              <span>{{ item.total }}</span>
            </div>
          </template>
        </b-table>
        <!--statistical graphic -->
        <div v-else-if="!isLoading" class="d-block">
          <graphics-components
            :xAxisData="xAxisData"
            :yAxisData="yAxisData"
            @selectItemChart="selectItemChart"
          />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import fields from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/data/forBureau.fields";
import GraphicsComponents from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/components/Graphics.vue";

export default {
  components: {
    GraphicsComponents,
  },
  props: {
    items: {
      require: true,
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    viewDefault: {
      type: String,
      default: 'list',
      required: false,
    },
  },
  data() {
    return {
      fields,
      typeView: "list",
      xAxisData: [],
      yAxisData: {},
    };
  },
  methods: {
    sendParams({ process, condition }, valueCondition) {
      const params = { process, condition, valueCondition };
      this.$emit("selectedItem", params);
    },
    generateChart() {
      const seriesA = this.items.map((item) => item.transunion);
      const seriesB = this.items.map((item) => item.experian);
      const seriesC = this.items.map((item) => item.equifax);

      // params to graphic
      this.yAxisData = {
        transunion: seriesA,
        experian: seriesB,
        equifax: seriesC,
      };

      // const xAxisData = this.items.map((item) => item.item);
      const xAxisData = ['by soft', 'by specialists','by seller', 'sold', 'disputed', 'deleted / updated'];
      const newXAxisData = xAxisData.map((item, index) => {
        const total = parseInt(seriesA[index]) + parseInt(seriesB[index]) + parseInt(seriesC[index])
        return {
          name: item,
          total
        }
      });
      this.xAxisData = newXAxisData;
    },
    selectItemChart({ xAxisIndex, yAxisIndex }) {
      const bureausId = {
        0: 1, //transunion
        1: 2, // experian
        2: 3, // equifax
      };
      const { process, condition } = this.items[xAxisIndex];
      const valueCondition = bureausId[yAxisIndex];
      this.sendParams({ process, condition }, valueCondition);
    },
  },
  mounted(){
    if(!!this.viewDefault){
      this.typeView = this.viewDefault;
    }
  },
  watch: {
    typeView(newValue) {
      if (newValue) {
        this.generateChart();
      }
      this.$emit('selectView', newValue);
    },
  },
};
</script>
<style scoped>
* {
  --gray: #717d7e8e;
  --blue: #0090e7;
}
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th,
.custom-table-dark >>> thead tr th,
.custom-table-dark >>> tfoot tr th {
  background-color: #0090e7 !important;
  border-style: none !important;
  color: white !important;
}
.custom-table >>> thead tr th:first-child,
.custom-table-dark >>> thead tr th:first-child {
  border-top-left-radius: 10px !important;
}
.custom-table >>> thead tr th:last-child,
.custom-table-dark >>> thead tr th:last-child {
  border-top-right-radius: 10px !important;
}
.custom-table >>> tbody tr td .custom-td-item {
  background-color: #fafafa !important;
  height: 70px !important;
}
.custom-table-dark >>> tbody tr td .custom-td-item {
  background-color: transparent !important;
  height: 70px !important;
}
.custom-table >>> tbody tr td,
.custom-table-dark >>> tbody tr td {
  border-style: none !important;
  padding: 3px 0px !important;
}
.custom-table >>> tbody tr:first-child td,
.custom-table-dark >>> tbody tr:first-child td {
  padding-top: 8px !important;
}
.custom-table >>> tbody tr td:first-child .custom-td-item,
.custom-table-dark >>> tbody tr td:first-child .custom-td-item {
  border-radius: 10px 0px 0px 10px !important;
  background-color: #d5effa !important;
}
.custom-table-dark >>> tbody tr td:first-child .custom-td-item {
  border: 1px solid #2980b9 !important;
}
.custom-table >>> tbody tr td:last-child .custom-td-item,
.custom-table-dark >>> tbody tr td:last-child .custom-td-item {
  border-radius: 0px 10px 10px 0px !important;
}
.custom-table-dark >>> tbody tr td .custom-td-item {
  border-top: 1px solid var(--gray) !important;
  border-bottom: 1px solid var(--gray) !important;
}
.custom-table-dark >>> tbody tr td:last-child .custom-td-item {
  border-right: 1px solid var(--gray) !important;
}
.custom-table-dark >>> tbody tr td {
  background-color: transparent !important;
}

.custom-table >>> tbody tr td:last-child,
.custom-table-dark >>> tbody tr td:last-child {
  border-left: 2px solid var(--blue) !important;
  border-right: 2px solid var(--blue) !important;
}
.custom-table >>> tbody tr:last-child td:last-child,
.custom-table-dark >>> tbody tr:last-child td:last-child {
  border-bottom: 2px solid var(--blue) !important;
  border-radius: 10px !important;
}

.custom-table-dark >>> tbody tr td:first-child .custom-td-item {
  background-color: #2980b9 !important;
  color: white;
}
.text-underline {
  text-decoration: underline !important;
}
.custom-bg-gray {
  background-color: #fafafa !important;
}
.custom-bg-gray-dark {
  background-color: #2f2f31 !important;
}
.font-size-md {
  font-size: 14px;
}
.hoverable-row:hover{
  box-shadow: rgba(0, 144, 231, 0.3) 0px 30px 60px -12px inset,
    rgba(0, 144, 231, 0.3) 0px 18px 36px -18px inset;
  transition: box-shadow 0.3s ease !important;
  cursor: pointer;
}
</style>
